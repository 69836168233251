jQuery(window).on("load", function () {

    if ($("header .menu").offset().top > 100) {
        setTimeout(function () {
            $('header .menu').addClass('menu-up menu-down');
        }, 100);
    }

    $('.team-block .slider').css('opacity', '1');

     [].forEach.call(document.querySelectorAll('img[data-src]'), function (img) {
        img.setAttribute('src', img.getAttribute('data-src'));
        img.onload = function () {
            img.removeAttribute('data-src');
        };
    });

});

$(document).ready(function () {

    $("#menu").mmenu({
        "extensions": [
                "position-right",
                "pagedim-black",
                "border-offset"
             ],
        classNames: {
            fixedElements: {
                fixed: "fix-menu"
            },
            vertical: "expand"
        }
    });

    $("#menu").mhead();

    var api = $('#menu').data('mmenu');
    api.bind("close:finish", function () {
        $('.icon').removeClass('open');
    });

    $("nav .btn").click(
        function (evnt) {
            evnt.preventDefault();
            api.close();
        }
    );

    $('.lang .wrap').click(function () {
        $(this).next().toggleClass('show');
    });

    $(document).mouseup(function (e) {
        var container = $(".lang");
        if ($(".lang").has(e.target).length === 0) {
            $('.lang ul').removeClass('show');
        }
    });

    $('.drop .choose').click(function () {
        $('.drop .drop-menu').slideUp(200);
        if ($(this).next().css('display') == "block") {
            $(this).next().slideUp(200);
        } else {
            $(this).next().slideDown(200);
        }
    });

    $('.drop p').click(function () {
        $(this).parents('.drop').addClass('active').find('.choose').val($(this).text());
        $('.drop .drop-menu').slideUp(200);
    });

    $(document).mouseup(function (e) {
        var container = $(".drop");
        if ($(".drop").has(e.target).length === 0) {
            $('.drop .drop-menu').slideUp(200);
        }
    });

    //$('.services-block').css('margin-top', -$('.services-block').height())

    //$('.team-block .bg').css('height', 110 + $('.team-block h2').height() + 48 + $('.team-block p').height() + 60 + $('.team-block img').height());

    $('.team-block .slider').slick({
        //centerMode: true,
        //centerPadding: '160px',
        dots: true,
        arrows: false,
        infinite: false,
        slidesToShow: 6,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
          ]
    });

    $('header').css('height', $('header').height());

    setTimeout(function () {
        $('.team-block .bg').css('height', 70 + $('.team-block h2').height() + 8 + $('.team-block p').height() + 60 + $('.team-block img').height());
    }, 500);

    $(window).resize(function () {
        setTimeout(function () {
            $('.team-block .bg').css('height', 70 + $('.team-block h2').height() + 8 + $('.team-block p').height() + 60 + $('.team-block img').height());
        }, 500);
    });

    if ($(window).width() < 1200) {
        var lastScrollTop = 0;
        $(window).scroll(function (event) {
            var st = $(this).scrollTop();
            if (st > $(window).height()) {
                $('.fix-menu').addClass('mh-scrolledout');
            } else {
                $('.fix-menu').removeClass('mh-scrolledout');
            }
            if (st > lastScrollTop) {
                $('.fix-menu').addClass('menu-down');
            } else if (st == 0) {
                $('header').removeClass('menu-down');
            } else {
                $('.fix-menu').removeClass('mh-scrolledout');
            }

            lastScrollTop = st;

        });

        //$('.services-block').css('margin-top', -$('.services-block').height() + 27)


        /*$('.services-block .row').slick({
            infinite: false,
            slidesToShow: 4,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3
                    }
            },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2
                    }
            },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1
                    }
            }
          ]
        });*/
    } else {
        //$('.services-block').css('margin-top', -$('.services-block').height())

        var heightHeader = $('header .container').height();
        var lastScrollTop = 0;
        $(window).scroll(function (event) {
            var st = $(this).scrollTop();

            if (st > $(window).height()) {
                $('header .menu').addClass('menu-up');
            } else {
                $('header .menu').removeClass('menu-up');
            }
            if (st > heightHeader) {
                $('header .menu').addClass('menu-down');
            } else {
                $('header .menu').removeClass('menu-down');
            }
            if (st < lastScrollTop) {
                $('header .menu').removeClass('menu-up');
            }

            lastScrollTop = st;
        });
    }

    $('.jarallax').jarallax({
        speed: 0.2
    });


    var location = window.location.href;
    var cur_url = location.split('/').pop();
    $('header ul li').each(function () {
        var link = $(this).find('a').attr('href');

        if (cur_url == link) {
            $(this).addClass('current');
        }
    });

    $('.contact-block form .btn').click(function () {
        setTimeout(function () {
            $('.drop label').remove()
        }, 500);
    })

    $('.contact-form select, .contact-form select').selectToAutocomplete();
    $('.contact-form .selectCountry').append($('#ui-id-1'));

    $('.contact-block form').validate({
        submitHandler: function (form) {
            $('.drop label').remove()
            $('.contact-block form').ajaxSubmit({
                clearForm: true,
                success: function () {
                    $('.toast-body').css('color', '#28a745');
                    $('.toast-body').text($('.contact-block form').data('success'));
                    $('.toast').toast('show');
                    $('.contact-block form').trigger('reset');
                },
                error: function () {
                    $('.toast-body').css('color', '#F03E30');
                    $('.toast-body').text($('.contact-block form ').data('error'));
                    $('.toast').toast('show');
                }
            });

        }
    });

    $('.subscribe-form').validate({
        submitHandler: function (form) {

            $('.subscribe-form').ajaxSubmit({
                clearForm: true,
                success: function () {
                    $('.toast-body').css('color', '#28a745');
                    $('.toast-body').text($('.subscribe-form').data('success'));
                    $('.toast').toast('show');
                    $('.blog-block form').trigger('reset');
                },
                error: function () {
                    $('.toast-body').css('color', '#F03E30');
                    $('.toast-body').text($('.subscribe-form').data('error'));
                    $('.toast').toast('show');
                }
            });

        }
    });

    $("[data-paroller-factor]").paroller({
        type: 'foreground',
        direction: 'vertical'
    });

    $('.scroll').on("click", function (event) {
        event.preventDefault();
        var anchor = $(this).attr('href');
        $('body,html').animate({
            scrollTop: $(anchor).offset().top
        }, 800);
    });

    /*var desktop = true;
    $(window).resize(function () {
        if ($(window).width() < 1360 && desktop == true) {
            $('.services-block .row').slick({
                infinite: false,
                slidesToShow: 4,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3
                        }
                },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2
                        }
                },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 1
                        }
                }
              ]
            });
            //$('.services-block').css('margin-top', -$('.services-block').height() + 27)
            desktop = false;
        } else if ($(window).width() > 1360 && desktop == false) {
            $('.services-block .row').slick('unslick');
            desktop = true;
        }
    });*/

});
